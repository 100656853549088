<script>
  import { onMount } from "svelte";
  import { navigateTo } from "svelte-router-spa";
  //import axios from "axios";
  import axios from "../../axios-global.js";//this is for axios authorization

  import { v_user } from "../../stores.js";
  import Icon from "svelte-awesome";
  import { refresh, times } from "svelte-awesome/icons";


  let v_lunchWeekList = [];
  let v_loading = true; // intialize variable // this just to show spinner icon before loading data

  let showCreateModal = false;
  let createWeekOfDate = null;
  
  let weekToDelete = {};
  //modal tailwind
  let showDeleteModal = false;

  const toggleCreateModal = () => {
   showCreateModal = true;
  };

  const toggleDeleteModal = (v_lunchWeek) => {
    weekToDelete = v_lunchWeek;
    showDeleteModal = true;
  };


  onMount(async () => {
    try {
      console.log("ffffff");
     // const v_response = await axios.get("http://localhost:3000/api/lunch-week");
       const v_response = await axios.get(`${process.env.API_ROOT}/api/lunch-week`);
      v_lunchWeekList = v_response.data;
      v_loading = false;
    } catch (e) {
      console.error(e);
    }
  });

  const createLunchWeek = async() => {
    showCreateModal = false; //toggle create model off

    let newLunchWeek = {
      week_of: createWeekOfDate,
      is_published: false,
    }
    try{
      v_loading = true;
      const response = await axios.post(`${process.env.API_ROOT}/api/lunch-week`, newLunchWeek);
      const lunch_week_id = response.data.id;
      newLunchWeek.lunch_week_id = lunch_week_id;
      v_lunchWeekList.push(newLunchWeek);
      v_loading = false;
    }catch(e){
      v_loading = false;
      console.error(e);
    }

  };

  const deleteLunchWeek = async v_lunchWeek => {
    
    showDeleteModal = false; //toggle delete model off

    const lunchWeekId  = v_lunchWeek.lunch_week_id;
    console.log(lunchWeekId);
    try{ 
      v_loading = true;
      await axios.delete(`${process.env.API_ROOT}/api/lunch-week/${lunchWeekId}`);
      //this is to refresh the list in frontend
      const deletedIndex = v_lunchWeekList.findIndex(
        x => x.lunch_week_id ===lunchWeekId);
        v_lunchWeekList.splice(deletedIndex, 1);

        v_loading = false;
    }catch(e){
      v_loading = false;
      console.error(e);
    }
  };

  const openLunchWeekDetails = (v_lunchWeek) => {
    const route = `/admin/manage-menus/week-details/${v_lunchWeek.lunch_week_id}`;
    navigateTo(route);
  };



</script>

<p>7az9a</p>

<div>
  <ul>
    <li><a href="/">Home</a></li>
    <li><a href="/admin/manage-menus">Lunch Menu Administration</a></li>
    <li><a href="/#">{$v_user.schoolName}</a></li>
  </ul>

   <!-- //////////////////////////////////////// -->

  <button class="bg-white p-2 border-solid border-[1px] border-gray-300 rounded" on:click={()=> toggleCreateModal()}>add lunch week</button>
  <div class="h-6"></div>
  <!-- ////////////////////////////////////////// -->

  {#if v_loading}
    <div class="section">
      <Icon spin data="{refresh}" scale="3" />
    </div>
  {:else}
    <table>
      <thead>
        <tr>
          <th>Week Of</th>
          <th>Published</th>
          <th>Actions</th>
        </tr>
      </thead>
      {#each v_lunchWeekList as v_lunchWeek}
        <tr>
          <td >
          <button on:click="{openLunchWeekDetails(v_lunchWeek)}">{v_lunchWeek.week_of}</button>
        </td>
          <td>{v_lunchWeek.is_published}</td>
          <!-- we can show modal here to confirm delete -->
          <td ><button on:click={toggleDeleteModal(v_lunchWeek)}> <Icon data="{times}"></Icon> </button>
          </td>
        </tr>
      {/each}
    </table>
  {/if}
</div>

<p>7az9a</p>


<!-- ///////////////////////////////////////////// -->

{#if showDeleteModal}
<div class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex">
  <div class="relative w-auto my-6 mx-auto max-w-sm">
    <!--content-->
    <div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
      <!--header-->
      <div class="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
        <h3 class="text-3xl font-semibold">
          Delete
        </h3>
        <!-- <button class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none" on:click={toggleDeleteModal}>
          <span class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
            ×
          </span>
        </button> -->
      </div>
      <!--body-->
      <div class="relative p-6 flex-auto">
        <p class="my-4 text-blueGray-500 text-lg leading-relaxed">
         do you want to delete lunch week ? {weekToDelete.week_of}.
        </p>
      </div>
      <!--footer-->
      <div class="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
        <button class="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" on:click={()=> (showDeleteModal = false)}>
          Close
        </button>
        <button class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" on:click={deleteLunchWeek(weekToDelete)}>
          Save Changes
        </button>
      </div>
    </div>
  </div>
</div>
<div class="opacity-25 fixed inset-0 z-40 bg-black"></div>
{/if}
<!-- ////////////////////////////////////////////////////// -->

{#if showCreateModal}
<div class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex">
  <div class="relative w-auto my-6 mx-auto max-w-sm">
    <!--content-->
    <div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
      <!--header-->
      <div class="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
        <h3 class="text-3xl font-semibold">
          Add lunch Week
        </h3>
      </div>
      <!--body-->
      <div class="relative p-6 flex-auto">

        <input class="bg-white border-solid  border-gray-300 rounded border-2" type="text" placeholder="yyyy/mm/dd" bind:value="{createWeekOfDate}">
      </div>
      <!--footer-->
      <div class="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
        <button class="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" on:click={()=> (showCreateModal = false)}>
          Close
        </button>
        <button class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" on:click="{()=>{createLunchWeek()}}">
          Save Changes
        </button>
      </div>
    </div>
  </div>
</div>
<div class="opacity-25 fixed inset-0 z-40 bg-black"></div>
{/if}
<script>
    import Navbar from '../public/Navbar.svelte'
    import Footer from '../public/Footer.svelte'
  
      let titlecolor = "text-green-900";
      let titleSize = "text-3xl";
  
  
      const v_imageStyle="h-auto max-w-full rounded-md";
      const v_divStyle = "absolute inset-0 z-10 w-full h-full flex opacity-0 bg-white hover:opacity-70 duration-100 ";
      const v_pStyle = `absolute inset-0 z-10 flex justify-center items-center opacity-0 p-10 hover:opacity-100 duration-100 ${titleSize} font-bold ${titlecolor}`;
      
      let projects = [
         
      ];
  
  </script>
  
  <svelte:head>
      <title>Mostafa Ballaj - UX/UI Designer</title>
      <meta property="description" content="hello world" />
  </svelte:head>
  
  <!-- navbar -->
  <Navbar></Navbar>
  <!-- navbar -->
  
  <!-- content -->
  
  <body class=" h-max bg-[#fff6ed] " >
  
    <div class=" h-10 " ></div>
  
  <div class="grid grid-cols-2 md:grid-cols-4 gap-4 pl-10 pr-10">
  
      {#each projects as project}
          <figure  class=" relative" >
              <a href="/">
              <img class={v_imageStyle}  src={project.image} alt="" >
                  <div class={v_divStyle} >
                      <p class={v_pStyle} >
                          {#if project.locked}
                              <i class="fa-solid fa-lock absolute "></i><br><br><br>
                          {/if}
                          {project.title}</p>
                     
                  </div>
              </a>
          </figure >
      {/each}
  </div>
  
  
  <br>
  
    <!-- <p>footer</p> -->
  
  <Footer></Footer>
  
  </body>
  
<script>

</script>

<!-- navbar -->
<div class=" bg-green-900 h-32 grid items-center " >
    <div class="grid grid-cols-3 pr-10 pl-10"  >

      <div class="hidden md:flex gap-8 items-center">
        <a class="text-gray-100 " href="/Design">Design</a>
        <a class="text-gray-100 " href='/3D'>3D</a>
        <a class="text-gray-100 " href='/UX'>UX</a>
        <a class="text-gray-100 " href='/UI'>UI</a>
        <a class="text-gray-100 " href='/Ideas'>Ideas</a>
        <a class="text-gray-100 " href='/About'>About</a>

      </div>

      <div class= " text-gray-300 justify-center flex items-center text-2xl lg:text-3xl font-bold gap-4" >
        <img src="" alt="" height="50" width="50">
        <a class=" hidden xl:flex" href="/">MOSTAFA BALLAJ</a>
      </div>  

      <div class="hidden md:flex gap-8 justify-end items-center " >
        <a class="text-gray-100 " href='https://linkedin.com/in/mostafaballaj/' target="_blank" >
          <i class="fa-brands fa-linkedin-in fa-lg" style="color: #dedede;"></i>
        </a>
        <a class="text-gray-100 " href='https://instagram.com/mostafaballaj/' target="_blank" >
          <i class="fa-brands fa-instagram fa-lg"  style="color: #dedede;"></i>
        </a>
        <a class="text-gray-100 " href='https://behance.net/blj/' target="_blank" >
          <i class="fa-brands fa-behance fa-lg"  style="color: #dedede;"></i>
        </a>
        <a class="text-gray-100 " href='/' >
          <i class="fa-regular fa-envelope fa-lg" style="color: #dedede;"></i>
        </a>
      </div>

      <div>
      </div>

      <div class=" md:hidden flex justify-end items-center " >
        <i class="fa-solid fa-bars fa-2xl" style="color: #e3e3e3;"></i>
      </div>

    </div>
</div>
  <!-- navbar -->
<script>
  import { Navigate } from 'svelte-router-spa'
  import Navbar from './Navbar.svelte'
  import Footer from './Footer.svelte'

    let titlecolor = "text-green-900";
    let titleSize = "text-3xl";


    const v_imageStyle="h-auto max-w-full rounded-md";
    const v_divStyle = "absolute inset-0 z-10 w-full h-full flex opacity-0 bg-white hover:opacity-70 duration-100 ";
    const v_pStyle = `absolute inset-0 z-10 flex justify-center items-center opacity-0 p-10 hover:opacity-100 duration-100 ${titleSize} font-bold ${titlecolor}`;
    
    let projects = [
        {
            title: "3D UI artwork",
            image: "",
            link: "/",
            locked: false
        },
        {
            title: "3D UI artwork",
            image: "",
            link: "/",
            locked: false
        },
        {
            title: "3D phone artwork",
            image: "",
            link: "/",
            locked: false
        },
        {
            title: "Phone presentation",
            image: "",
            link: "/",
            locked: false
        },
        {
            title: "3D tech artwork",
            image: "",
            link: "/",
            locked: false
        },
        {
            title: "Moroccan jewelry ring ",
            image: "",
            link: "/",
            locked: false
        },
        {
            title: "Minimal landscapes",
            image: "",
            link: "/",
            locked: false
        },
        {
            title: "Jewellry 3D artworks",
            image: "",
            link: "/",
            locked: false
        },
        {
            title: "Moroccan jewellry animation",
            image: "",
            link: "/",
            locked: false
        },
        {
            title: "Purple scape",
            image: "",
            link: "/",
            locked: false
        },
        {
            title: "Moroccan inspired shapes",
            image: "",
            link: "/",
            locked: false
        },
        {
            title: "The main shape",
            image: "",
            link: "/",
            locked: false
        },
        {
            title: "Chilling",
            image: "",
            link: "/",
            locked: false
        },
        {
            title: "Perfect Tiles",
            image: "",
            link: "/",
            locked: false
        },
        {
            title: "Moroccan snow ball",
            image: "",
            link: "/",
            locked: false
        }
  
    ];

</script>

<svelte:head>
    <title>Mostafa Ballaj - UX/UI Designer</title>
    <meta property="Mostafa Ballaj design Portfolio" content="Mostafa Ballaj 3D UX UI portfolio" />
</svelte:head>

   
<Navigate.default to="/">
</Navigate.default>
 
<!-- navbar -->
<Navbar></Navbar>
<!-- navbar -->

<!-- content -->

<body class=" h-max bg-[#fff6ed] " >

  <div class=" h-10 " ></div>

<div class="grid grid-cols-2 md:grid-cols-4 gap-4 pl-10 pr-10">

    {#each projects as project}
        <figure  class=" relative" >
            <a href="/">
            <img class={v_imageStyle}  src={project.image} alt="" >
                <div class={v_divStyle} >
                    <p class={v_pStyle} >
                        {#if project.locked}
                            <i class="fa-solid fa-lock absolute "></i><br><br><br>
                        {/if}
                        {project.title}</p>
                   
                </div>
            </a>
        </figure >
    {/each}
</div>


<br>

  <!-- <p>footer</p> -->

<Footer></Footer>

</body>

<script>

</script>

<!-- footer -->

<div>
    <div class=" bg-gray-200 h-20 flex place-items-center justify-center" >
        <p>By Mostafa Ballaj 2023</p>
    </div>
</div>

<!-- footer -->
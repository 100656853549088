<script>
  import { Route } from "svelte-router-spa";
  import { onMount } from "svelte";
  import { v_user } from "../../stores.js";

  import createAuth0Client from "@auth0/auth0-spa-js";
  import auth0Config from "../../auth0-config.js";

  export let currentRoute;
  let initialized = false;
  let auth0;

  onMount(async () => {
    auth0 = await createAuth0Client(auth0Config);
    const authenticated = await auth0.isAuthenticated();

    if (!authenticated) {
      await auth0.loginWithRedirect({
        redirect_uri: `${window.location.origin}/callback`,
        appState: window.location.pathname,
      });
    } else {
      v_user.set({
        name: "test",
        school: "test",
      });
      initialized = true;
    }
  });

  const logout = () => {
    auth0.logout ({
      returnTo: window.location.origin,
    })
  };
</script>

<div class="bg-slate-200 ">
  <div class="bg-slate-400 h-12 text-black">atay</div>
  {#if initialized}
    <button class=" bg-white rounded p-2 " on:click="{() => logout()}">Logout</button>
    <Route.default {currentRoute} />
  {/if}
</div>

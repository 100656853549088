<script>
  import { v_user } from "../../stores.js";
  import { onMount } from "svelte";
  //import axios from "axios";
  import axios from "../../axios-global.js";//this is for axios authorization

  import { add, parseISO, format } from "date-fns";
  // import AdminLayout from "./AdminLayout.svelte";

  //import Icon from "svelte-awesome";
  //import { refresh } from "svelte-awesome/icons";

  export let currentRoute;
  let routeLunchWeekId = currentRoute.namedParams.lunchWeekId;

  //lunch week array list of days
  let v_lunchWeek = {
    v_lunchDays: [],
  };

  let v_loading = true;

  onMount(async () => {
    try {
      const response = await axios.get(`${process.env.API_ROOT}/api/lunch-week/${routeLunchWeekId}`);
      // console.log(routeLunchWeekId);
      v_lunchWeek = response.data;
      // console.log(v_lunchWeek);
      seedLunchDays();
      v_loading = false;
    } catch (e) {
      v_loading = false;
      console.error(e);
    }
  });

  const save = async () => {
    for (let i = 0; i < v_lunchWeek.v_lunchDays.length; i++) {
      let v_lunchDay = v_lunchWeek.v_lunchDays[i];

      console.log(v_lunchDay);

      if (v_lunchDay.lunch_day_id) {
        await axios.put(`${process.env.API_ROOT}/api/lunch-week/${routeLunchWeekId}/lunch-day/${v_lunchDay.lunch_day_id}`, v_lunchDay);
      } else {
        const response = await axios.post(`${process.env.API_ROOT}/api/lunch-week/${routeLunchWeekId}/lunch-day`, v_lunchDay);

        v_lunchDay.lunch_day_id = response.data.lunch_day_id;
        //console.log(v_lunchDay.lunchDayId);
        //console.log(v_lunchDay.lunch_day_id);

        //
      }
    }
  };

   const togglePublish = async() => {
     v_lunchWeek.is_published = !v_lunchWeek.is_published;
     await axios.put(`${process.env.API_ROOT}/api/lunch-week/${routeLunchWeekId}`, {"is_published": v_lunchWeek.is_published});
   };

   
  const seedLunchDays = () => {
    const WeekOfDate = parseISO(v_lunchWeek.week_of);
    for (let i = 0; i < 5; i++) {
      const calculatedDay = add(WeekOfDate, { days: i });
      const formatedDay = format(calculatedDay, "yyyy-MM-dd");
      if (v_lunchWeek.v_lunchDays.some((x) => x.day === formatedDay)) {
        continue;
      }

      const v_lunchDay = {
        day: formatedDay,
        lunch_week_id: v_lunchWeek.lunch_week_id,
        menu_details: null,
      };
      v_lunchWeek.v_lunchDays.splice(i, 0, v_lunchDay);
      // console.log(v_lunchWeek.v_lunchDays);
    }
  };
</script>

<div>
  <ul>
    <li>
      <a href="/">Home</a>
    </li>
    <li>
      <a href="/admin/manage-menus">Lunch Menu Administration</a>
    </li>
    <li>
      <a href="/#">{$v_user.schoolName}</a>
    </li>
  </ul>
</div>

<div>

  <p>kharya</p>
  <div>{JSON.stringify(v_lunchWeek)}</div>
  <p>kharya</p>
  <button class="bg-white p-4 rounded " on:click="{() => save()}">Save</button>
  <button class="bg-white p-4 rounded " on:click={()=> togglePublish()}>{v_lunchWeek.is_published ? 'Unpublished' : 'Publish'}</button>
  <br />

  <p>{v_lunchWeek.v_lunchDays}</p>
  <br />
  <div class=" flex gap-4 ">
    {#each v_lunchWeek.v_lunchDays as v_lunchDay}
      <div>
        <p>{format(parseISO(v_lunchDay.day), 'EEE dd/MM/yyyy')}</p>
        <textarea bind:value="{v_lunchDay.menu_details}" name=" " id=" " cols="20" rows="6"></textarea>
      </div>
    {/each}
  </div>

</div>
<br />
